import { useEffect } from "react";
import Image from "next/image";
import Link from "next/link";

import {
	ga_logProductsImpression,
	ga_logPromotionsImpression,
} from "../../utils/analytics";

const getFeaturedImage = (images) => {
	const filteredImages = images.filter((img) => img.featuredImage == true);

	return filteredImages[0] ? filteredImages[0] : null;
};

const formatGAEventItems = (items, page) => {
	const creativeSlot = page == "books" ? "books" : "related-products";

	return items.map((book) => {
		return {
			item_name: book.title,
			item_id: book.isbn,
			item_category: "Book",
			quantity: 1,
			item_lists_name: "Amazon Books",
			price: book.price,
		};
	});
};

export default function BookCardsScrollable({ bookData, page }) {
	useEffect(() => {
		ga_logProductsImpression(formatGAEventItems(bookData, page));
	}, [bookData, page]);

	return (
		<div className="flex ">
			<div
				className="flex w-full  snap-x snap-mandatory gap-x-4 md:gap-x-6  md:grid lg:gap-x-4 md:grid-cols-5 xl:gap-x-8 overflow-x-scroll lg:overflow-visible hide-scroll-bar "
				aria-label="Scrollable books section. Use arrow keys to scroll."
			>
				{bookData.map((book) => (
					<Link
						href={`/books/${book.handle}`}
						className="group snap-start"
						key={book.title}
					>
						<div className="flex flex-col text-sm w-36 md:w-full  text-gray-900 ">
							<div className="relative w-5/6 mt-2 mx-auto group-hover:opacity-75 ">
								{/* <div className="drop-shadow-md"> */}
								<div className=" h-48 lg:h-72 xl:h-80 flex items-end drop-shadow-md">
									<Image
										src={getFeaturedImage(book.images).url}
										alt={getFeaturedImage(book.images).alt}
										width={getFeaturedImage(book.images).width}
										height={getFeaturedImage(book.images).height}
										className="rounded-sm "
									/>
								</div>
								{/* {book?.label && (
									<div className="absolute bottom-0 left-0 text-sm uppercase  bg-red-600 text-white py-1 px-3">
										{book.label}
									</div>
								)} */}
							</div>
							<div className="relative px-2 mt-1.5 ml-2 pb-1">
								<h3 className="mt-2 text-sm text-left font-medium text-gray-900 ">
									{book.title}
								</h3>
								{book?.label && (
									<p className="text-xs mt-1 uppercase rounded-sm w-fit  bg-red-600 text-white py-0 px-1">
										{book.label}
									</p>
								)}
								<div className="flex flex-row items-center mt-2"></div>
							</div>
						</div>
					</Link>
				))}
			</div>
		</div>
	);
}
