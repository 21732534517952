import { useEffect, useState } from "react";

import Image from "next/image";

import { getCollectionByVariant } from "@/lib/shopify";
import Link from "next/link";
import { useCartContext } from "@/context/CartContext";
import { twMerge } from "tailwind-merge";
import { useQuery } from "@tanstack/react-query";
import DisplayPrice from "../common/DisplayPrice";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { ArrowLongRightIcon } from "@heroicons/react/24/outline";
import { TagIcon } from "@heroicons/react/20/solid";

const courses = {
	Bundled: [
		{
			title: "Getting Started with ASL",
			handle: "level-1a-online-asl-course",
			description:
				"The first course in our ASL series! Get a basic understanding of ASL and deaf culture.",
			subText: "5 Lessons",
			bgColor: "#F1615F",
			textColor: "#401f1d",
			flagText: "",
			level: "1A",
			levelText: "Level 1A",
			image: {
				url: "/img/course/level-1a-image_LG.png",
			},
			type: "Course",
		},
		{
			title: "ASL Essential Bundle",
			handle: "asl-essentials-bundle",
			description:
				"Get our first 2 courses in the ASL series. The best value for beginners who are eager to embark on their ASL adventure!",
			subText: "10 Lessons",
			flagText: "Save 10%",
			levelText: "Levels 1A & 1B",
			image: {
				url: "/img/course/asl-essentials-bundle_LG.png",
			},
			bgColor: "#299be0",
			textColor: "#FFFFFF",
			type: "Bundle",
		},
		{
			title: "Complete Course Bundle",
			handle: "complete-course-bundle",
			description:
				"For those commited to learning it all, this bundle is for you. Save 25% when you bundle all 4 courses!",
			subText: "20 Lessons",
			flagText: "Save 25%",
			levelText: "Levels 1A,1B,2A,& 2B",
			image: {
				url: "/img/course/complete-bundle-image_LG.png",
			},
			bgColor: "#5B6DED",
			textColor: "#FFFFFF",
			type: "Bundle",
		},
	],
	Kids: [
		{
			title: "Discovering Sign Language",
			handle: "sign-language-101-for-kids-level-1a",
			subText: "5 Lessons",
			bgColor: "#feec74",
			textColor: "#665900",
			description:
				"The first course in our Kids ASL series gives your child a solid foundation in ASL and deaf culture. ",
			levelText: "Level 1A",
			image: {
				url: "/img/course/kids-level-1a-image_LG.png",
			},
			type: "Course",
		},
		{
			title: "Going Beyond the Basics",
			handle: "sign-language-101-for-kids-level-1b",
			subText: "5 Lessons",
			bgColor: "#8880bc",
			textColor: "#262533",
			description:
				"This course builds on the topics covered in Kids Level 1A, giving your child a deeper understanding of ASL and deaf culture. ",
			levelText: "Level 1B",
			image: {
				url: "/img/course/kids-level-1b-image_LG.png",
			},
			type: "Course",
		},
		{
			title: "ASL Essentials Bundle for Kids",
			handle: "asl-essentials-bundle-for-kids",
			subText: "5 Lessons",
			bgColor: "#8880bc",
			textColor: "#262533",
			description:
				"Get our first 2 courses in the Kids ASL series. The best value for beginners who are eager to embark on their ASL adventure!",
			flagText: "Save 10%",
			levelText: "Levels 1A & 1B",
			image: {
				url: "/img/course/asl-essentials-bundle-for-kids_LG.png",
			},
			type: "Course",
		},
	],
};

const getDataForCourse = (courseHandle, courseList) => {
	if (courseList)
		return courseList.filter((course) => course.handle === courseHandle)[0];
	else return null;
};

const CourseBannerSmall = ({ type }) => {
	const { shopperIdentity } = useCartContext();

	const { data, isLoading, isFetching, refetch } = useQuery(
		["collection", "courses"],
		() =>
			getCollectionByVariant({
				collection: "active-courses",
				variantName: "Type",
				variantValue: "online course",
				reverse: true,
				countryCode: shopperIdentity.countryCode,
			})
	);

	useEffect(() => {
		const refreshQuery = async () => {
			refetch();
		};

		refreshQuery();
	}, [shopperIdentity.countryCode, refetch]);

	return (
		<div className="flex  ">
			<div
				className="flex w-full snap-x snap-mandatory px-8 lg:px-4 gap-x-4 xl:grid xl:grid-cols-4  overflow-scroll lg:overflow-visible hide-scroll-bar "
				aria-label="Scrollable courses section. Use arrow keys to scroll."
			>
				{courses[type].map((course, index) => {
					let courseInfo = getDataForCourse(course.handle, data);
					return (
						<Link
							href={`/courses/${course.handle}`}
							className="group"
							key={index}
						>
							<div className="flex w-[19rem] snap-center items-center flex-col md:flex-col  text-sm text-gray-900 ">
								<div className="relative w-full max-w-md  overflow-hidden group-hover:opacity-75 ">
									<Image
										src={course.image.url}
										alt={`${course.levelText} - ${course.title}`}
										className={twMerge(" w-full object-cover rounded-xl")}
										width={900}
										height={505}
									/>
									{course.flagText && false && (
										<div className="absolute rounded-tl-xl rounded-br-lg top-0 left-0 text-xs sm:text-sm uppercase  bg-red-600 text-white py-1 px-3">
											{course.flagText}
										</div>
									)}
									{!isLoading &&
										courseInfo?.tags &&
										courseInfo.tags.includes("on-sale") && (
											<div className="absolute top-0 rounded-bl-md rounded-tr-lg  right-0 text-xs sm:text-xs uppercase  bg-green-700 text-white py-0.5 px-3">
												<TagIcon className="h-3 w-3 inline mr-1" />
												On Sale!
											</div>
										)}
									{!isLoading &&
										courseInfo?.tags &&
										courseInfo.tags.includes("discounted") && (
											<div className="absolute top-0 rounded-bl-md rounded-tr-lg  right-0 text-xs sm:text-xs uppercase  bg-green-700 text-white py-0.5 px-3">
												<TagIcon className="h-3 w-3 inline mr-1" />
												Discounted!
											</div>
										)}

									{/* <div className="absolute text-sm left-1 bottom-1.5  shadow-md rounded-full backdrop-blur-md bg-white py-1 px-2 sm:px-4">
										{course.subText}
									</div> */}
								</div>
								<div className="flex w-full max-w-md flex-col relative mt-1.5 pb-1">
									<div className="flex mt-1 flex-row mb-2">
										{!isLoading &&
											courseInfo?.tags &&
											courseInfo.tags.includes("New") && (
												<div className="flex flex-col align-middle justify-center">
													<div className="">
														<p className=" mr-1 text-xs   uppercase rounded-sm w-fit  bg-red-600 text-white py-0 px-1">
															New!
														</p>
													</div>
												</div>
											)}
										<h3 className="inline-block group-hover:underline underline-offset-2 leading-tight text-sm font-medium text-gray-900 ">
											{courseInfo && !isLoading
												? courseInfo.title
												: course.title}
										</h3>
									</div>
									<div className="flex flex-row items-center justify-start">
										<p className=" text-sm line-clamp-3 text-ellipsis leading-5 text-slate-600 font-light">
											{course.levelText}
										</p>

										{!isLoading && courseInfo?.variantBySelectedOptions && (
											<p className=" text-sm font-medium  text-black ml-3">
												<span
													aria-hidden="true"
													className=" mr-2  text-gray-800 text-sm font-light line-through"
												>
													{courseInfo?.variantBySelectedOptions?.compareAtPrice
														?.amount &&
														shopperIdentity.currencyCode ==
															courseInfo.variantBySelectedOptions.compareAtPrice
																.currencyCode &&
														`$ ${parseFloat(
															courseInfo.variantBySelectedOptions.compareAtPrice
																.amount
														).toFixed(2)}`}
												</span>
												<span className="sr-only">
													{courseInfo?.variantBySelectedOptions?.compareAtPrice
														?.amount &&
														shopperIdentity.currencyCode ==
															courseInfo.variantBySelectedOptions.compareAtPrice
																.currencyCode &&
														`Original price $${parseFloat(
															courseInfo.variantBySelectedOptions.compareAtPrice
																.amount
														).toFixed(2)}`}
												</span>
												{courseInfo?.variantBySelectedOptions?.price?.amount &&
													shopperIdentity.currencyCode ==
														courseInfo.variantBySelectedOptions.price
															.currencyCode && (
														<>
															{courseInfo?.tags &&
																courseInfo.tags.includes("on-sale") &&
																`Now `}
															<DisplayPrice
																price={
																	courseInfo.variantBySelectedOptions.price
																}
															/>
														</>
													)}
											</p>
										)}
									</div>

									{/* <div className="flex  flex-col md:flex-col   mt-2">
										<p className=" text-sm leading-5 text-slate-500 ">
											{course.description}
										</p>
									</div> */}
								</div>
							</div>
						</Link>
					);
				})}
				<div className="mx-auto lg:w-full snap-center  xl:p-0">
					<Link href={`/courses`} className="group">
						<div className="relative   mx-auto  flex flex-col w-full ">
							<div className="h-[10.75rem] w-[19rem] xl:w-full  mx-auto flex flex-row bg-white hover:bg-gray-100 text-gray-700 relative pt-0 pb-2 px-2  rounded-2xl">
								{/* <div className="absolute  top-2 left-3">
									<Image
										src="/img/brand/sl101_logo_square.png"
										width="35"
										height="35"
										alt="Sign Language 101 logo"
									/>
								</div> */}

								<div className="flex flex-col justify-center  items-center w-full text-center">
									<Image
										src="/img/brand/sl101_logo_square.png"
										width="35"
										height="35"
										alt="Sign Language 101 logo"
									/>
									<h3 className="font-medium group-hover:underline font-heading text-base leading-tight  mb-2 mt-3 ">
										View All Courses
									</h3>
								</div>
							</div>
						</div>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default CourseBannerSmall;
