import { getCurrencySymbol } from "@/utils/shopify-utils";

const DisplayPrice = ({ price }) => {
	return (
		<>{`$${parseFloat(price.amount).toFixed(2)}${getCurrencySymbol(
			price.currencyCode
		)}`}</>
	);
};

export default DisplayPrice;
